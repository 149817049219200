import { defineComponent, watch, Teleport, PropType } from 'vue';
import styles from './index.css?module';
import { useAppStore } from '@/store';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    isBodyFixed: {
      type: Boolean,
      default: true,
      required: false,
    },
    onClose: {
      type: Function as PropType<() => void>,
    },
    onClosed: {
      type: Function as PropType<() => void>,
    },
  },

  setup(props, { slots, emit }) {
    const { t } = useI18n();
    const appStore = useAppStore();

    const updateBackButton = () => {
      appStore.showBackButton(() => {
        emit('close');
      });
    };

    const updateMainButton = () => {
      appStore.showMainButton(t('component.modal.closeMainBtn'), () => {
        emit('close');
      });
    };

    const updateButtons = () => {
      updateBackButton();
      updateMainButton();
    };

    let scrollTop = 0;
    watch(props, async () => {
      if (props.isOpen) {
        scrollTop = window.scrollY;
        if (props.isBodyFixed) {
          document.body.style.position = 'fixed';
        }
        updateButtons();
      } else {
        if (props.isBodyFixed) {
          document.body.style.position = '';
        }
        window.scroll(0, scrollTop);
        emit('closed');
      }
    });

    return () => (
      <>
        {props.isOpen && (
          <Teleport to="#modal">
            <div class={styles.modalWrapper}>{slots?.content?.()}</div>
          </Teleport>
        )}
      </>
    );
  },
});
